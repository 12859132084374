import { SubscriptionIntervals, SubscriptionPlans } from "@prisma/client";
import clsx from "clsx";
import { useSession } from "next-auth/react";

import Icon, { Icons } from "@musicfy/components/Icon";
import StartCheckoutButton from "@musicfy/components/PaymentButtons/StartCheckoutButton";
import {
  type AvailablePlans,
  PRICE_ID_TO_PRICE_MAP,
  PriceId,
  type TBasePlanInfo,
  getFormattedPrice,
  getSubscriptionPriceDetails,
} from "@musicfy/contants/Subscriptions";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

interface IPlanCardProps {
  interval: SubscriptionIntervals;
  details: TBasePlanInfo;
}

const PlanCard = ({ details, interval }: IPlanCardProps): JSX.Element => {
  const {
    pricesContext: { pricesData },
  } = usePaddleContext();

  const { data } = useSession();
  const { subscription } = useSubscriptionContext();

  const priceId = PriceId[`${details.plan}_${interval}`];
  const priceDetails = pricesData?.details.lineItems.find(({ price }) => {
    return price.id === priceId;
  });

  let price: number;

  if (!!priceDetails) {
    price = parseInt(priceDetails?.totals.total, 10) / 100;
  } else {
    // Use US locale price as fallback
    price = !!priceId ? PRICE_ID_TO_PRICE_MAP[priceId] / 100 : 0;
  }

  const {
    price: formattedMonthlyPrice,
    discountedPrice: discountedMonthlyPrice,
  } = getFormattedPrice(priceId, pricesData, SubscriptionIntervals.month);

  const {
    price: formattedYearlyPrice,
    discountedPrice: discountedYearlyPrice,
  } = getFormattedPrice(priceId, pricesData, SubscriptionIntervals.year);

  const isNewUser = !!data?.user
    ? Date.now() - new Date(data.user.createdAt).getTime() < ONE_DAY_IN_MS * 7
    : false;

  const isEligibleForDiscount =
    formattedMonthlyPrice !== discountedMonthlyPrice &&
    formattedYearlyPrice !== discountedYearlyPrice &&
    isNewUser &&
    !subscription;

  const { plan: pricePlan } = getSubscriptionPriceDetails({
    plan: details.plan,
    interval: interval,
  });

  const iconMap: {
    [key in AvailablePlans]: Icons;
  } = {
    pro: Icons.pricingCrown,
    starter: Icons.pricingBolt,
    studio: Icons.pricingTrophy,
    label: Icons.pricingTrophy,
  };

  const iconName = iconMap[details.plan];

  const isPopular = pricePlan === SubscriptionPlans.pro;

  return (
    <div
      className={clsx(
        "bg-gradient-to-b relative from-navy/30 to-navy border flex flex-col rounded-12 border-solid pb-6 drop-shadow-base w-full p-4",
        {
          "border-pink-200/60 backdrop-blur-3xl": isPopular,
          "border-white/20": !isPopular,
        }
      )}
    >
      {isPopular && (
        <div className="py-2 px-5 rounded-full text-pink-200 border border-pink-200 bg-[#160E23] max-w-max absolute right-0 left-0 mx-auto top-0 -translate-y-1/2">
          Most Popular
        </div>
      )}
      <div className="rounded-8 border border-white/[10%] p-6 bg-neutral-800/50">
        <div className="flex gap-4 items-center justify-between">
          <div>
            <div className="flex items-center justify-between">
              <div className="text-32 font-bold">{details.label}</div>
            </div>

            <div
              className={clsx(
                "mt-2 text-18 font-semibold text-transparent bg-clip-text bg-gradient-to-r",
                {
                  "from-pink-200 to-pink-400": isPopular,
                  "from-white/70 to-white": !isPopular,
                }
              )}
            >
              {details.benefits}
            </div>
          </div>
          <Icon name={iconName} />
        </div>
      </div>
      <div className="px-6">
        <div className="flex mt-6 gap-2">
          <div className="flex items-end">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-4">
                <div
                  className={clsx("text-42 font-bold", {
                    "text-gray-200 !text-28 opacity-60 relative before:contents-[''] before:absolute before:w-full before:h-1 before:top-0 before:bottom-0 before:my-auto before:bg-red/70":
                      isEligibleForDiscount,
                  })}
                >
                  {formattedMonthlyPrice}
                  {!isEligibleForDiscount && (
                    <span className="ml-1 text-20 text-pink-200 pb-[3px]">
                      /Month
                    </span>
                  )}
                </div>
                {isEligibleForDiscount && (
                  <div className="h-full bg-pink-200 rounded-8 px-4 py-2">
                    <div className="font-semibold lg:text-16 text-12 text-white">
                      First Month 60% Off
                    </div>
                  </div>
                )}
              </div>
              {isEligibleForDiscount && (
                <div className="text-42 font-bold">
                  {discountedMonthlyPrice}
                  <span className="ml-1 text-20 text-pink-200 pb-[3px]">
                    /Month
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2 gap-1">
          {interval === SubscriptionIntervals.year ? (
            <div className="tracking-wide text-white/70">
              {price === 0
                ? "Free"
                : ` Billed Yearly at ${
                    isEligibleForDiscount && !!discountedYearlyPrice
                      ? discountedYearlyPrice
                      : formattedYearlyPrice
                  } per year`}
            </div>
          ) : (
            <div className="text-white/70">
              {price === 0 ? "Free" : "Billed monthly"}
            </div>
          )}
        </div>
      </div>
      <div className="my-5 h-px w-full bg-white/20" />
      <div className="px-6 flex flex-grow flex-col gap-2">
        <div className="flex items-center gap-2">
          <Icon name="check" />
          <div>{details.generations}</div>
        </div>
        <div className="flex items-center gap-2">
          <Icon name="check" />
          <div>Unlock All Musicfy & Community Voices</div>
        </div>
        <div className="flex items-center gap-2">
          <Icon name="check" />
          <div>{details.speed} Speed</div>
        </div>
        <div className="flex items-center gap-2">
          <Icon name="check" />
          <div>{details.fileSizeLimit} Upload Limit</div>
        </div>
        {pricePlan !== SubscriptionPlans.starter && (
          <div className="flex items-center gap-2">
            <Icon name="check" />
            <div>Commercial License</div>
          </div>
        )}
      </div>
      <div className="mt-6 w-full px-6">
        <StartCheckoutButton
          priceId={priceId}
          discount={{
            id: priceDetails?.discounts[0]?.discount.id,
          }}
        />
      </div>
    </div>
  );
};

export default PlanCard;
